import { useEffect, useState } from "react";
import { MenuRequests } from "../MenuRequests";
import { BasicTable } from "components";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, Grid, TableCell } from "@mui/material";
import { Filtro } from "../components/Filtro";
import {
  getStaffRequest,
  getScheduledPermissions,
  getJustification,
  getJobPositionsRequest,
} from "api";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelect } from "@redux/slices/authUser";
import {
  setStaffRequest,
  recruitmentSelect,
  setStaffs,
  setUpdateStaffs,
} from "@redux/slices/recruitment";
import { setDataForTable, requestsSelect } from "@redux/slices/requests";
import { useRequests } from "hooks";
import { StaffRequestModal } from "./StaffRequestModal";
import { VacationRequestModal } from "./VacationRequestModal";
import { TypesOfPermitsModal } from "./TypesOfPermitsModal";
import { convertUTCToLocal } from "helpers";
import { setJobDescription } from "@redux/slices/jobDescription";
import { JustifyModal } from "./JustifyModal/JustifyModal";
import { PositionsModal } from "./PositionsModal";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const Petitions = () => {
  const { t } = useTranslation(["request"]["general"]);
  const dispatch = useDispatch();

  const { user } = useSelector(authUserSelect);
  const { updateStaffs, staffs, staffRequest } = useSelector(recruitmentSelect);
  const { dataForTable } = useSelector(requestsSelect);

  const { employees, vacancyReasons, jobsProfile } = useRequests();

  const [dataFilter, setDataFilter] = useState({});
  const [open, setOpen] = useState(false);

  const [openVacationRequest, setOpenVacationRequest] = useState(false);
  const [vacationReques, setVacationReques] = useState(null);

  const [openPermitsReques, setOpenPermitsReques] = useState(false);
  const [permitsReques, setPermitsReques] = useState(null);

  const [openJustification, setOpenJustification] = useState(false);
  const [justificationRequest, setJustificationRequest] = useState(null);

  const [openPositions, setOpenPositions] = useState(false);
  const [positionsRequest, setPositionsRequest] = useState(null);

  useEffect(() => {
    const fetchGetStaffRequest = async () => {
      try {
        const { data } = await getStaffRequest(user._id);
        dispatch(setStaffs(data));
        dispatch(setUpdateStaffs(false));
      } catch (error) {
        console.error("getStaffRequest: ", error);
      }
    };
    if (updateStaffs) {
      fetchGetStaffRequest();
    }

    const StaffRequest = async () => {
      try {
        if (employees && vacancyReasons && jobsProfile) {
          const rowsTable = staffs.map((e) => ({
            id: e._id,
            folio: e.folio,
            applicant: employees[e.applicant],
            isNewJob: e.isNewJob === true ? t("general:Si") : t("general:No"),
            vacancyReason: vacancyReasons[e.vacancyReason],
            vacancies: e.vacancies,
            status: t(`recruitment:${e.status}`),
          }));

          setDataFilter((prevDataFilter) => ({
            ...prevDataFilter,
            ["staffRequests"]: {
              type: "staffRequests",
              data: staffs,
              rows: rowsTable,
              headers: [
                t("recruitment:Folio"),
                t("recruitment:Applicant"),
                t("recruitment:IsNewJob"),
                t("recruitment:VacancyReason"),
                t("recruitment:Vacancies"),
                t("recruitment:status"),
              ],
            },
          }));

          dispatch(
            setDataForTable({
              type: "staffRequests",
              data: staffs,
              rows: rowsTable,
              headers: [
                t("recruitment:Folio"),
                t("recruitment:Applicant"),
                t("recruitment:IsNewJob"),
                t("recruitment:VacancyReason"),
                t("recruitment:Vacancies"),
                t("recruitment:status"),
              ],
            })
          );
        }
      } catch (error) {
        console.error("getStaffRequest: ", error);
      }
    };
    if (employees) {
      StaffRequest();
    }

    const VacationsAndPermissions = async () => {
      try {
        const { data } = await getScheduledPermissions(user._id);

        const dataVacations = data.filter((obj) => obj.type === "VACATION");
        const dataPermissions = data.filter((obj) => obj.type !== "VACATION");

        const rowsTableVacations = dataVacations.map((obj) => ({
          id: obj?._id,
          folio: obj?.folio,
          applicant: employees[obj?.applicant],
          startDate: convertUTCToLocal(obj?.startDate).format(
            "YYYY-MM-DD HH:mm"
          ),
          endDate: convertUTCToLocal(obj?.endDate).format("YYYY-MM-DD HH:mm"),
          status: t(`recruitment:${obj.status}`),
        }));

        const rowsTablePermissions = dataPermissions.map((obj) => ({
          id: obj?._id,
          folio: obj?.folio,
          applicant: employees[obj?.applicant],
          startDate: convertUTCToLocal(obj?.startDate).format(
            "YYYY-MM-DD HH:mm"
          ),
          endDate: convertUTCToLocal(obj?.endDate).format("YYYY-MM-DD HH:mm"),
          type: t(`request:${obj?.type}`),
          status: t(`recruitment:${obj?.status}`),
        }));

        setDataFilter((prevDataFilter) => ({
          ...prevDataFilter,
          ["vacationsRequest"]: {
            type: "vacationsRequest",
            data: dataVacations,
            rows: rowsTableVacations,
            headers: [
              t("recruitment:Folio"),
              t("recruitment:Applicant"),
              t("recruitment:startDate"),
              t("recruitment:endDate"),
              t("recruitment:status"),
            ],
          },
          ["permissionsRequest"]: {
            type: "permissionsRequest",
            data: dataPermissions,
            rows: rowsTablePermissions,
            headers: [
              t("recruitment:Folio"),
              t("recruitment:Applicant"),
              t("recruitment:startDate"),
              t("recruitment:endDate"),
              t("recruitment:type"),
              t("recruitment:status"),
            ],
          },
        }));
      } catch (error) {
        console.error(error);
      }
    };

    if (employees) {
      VacationsAndPermissions();
    }

    const Justify = async () => {
      try {
        const { data } = await getJustification(user._id);

        const rowsTablejustifyRequest = data.map((obj) => ({
          id: obj?._id,
          folio: obj?.folio,
          applicant: employees[obj?.applicant],
          type: t(`recruitment:${obj.type}`),
          status: t(`recruitment:${obj.status}`),
        }));

        setDataFilter((prevDataFilter) => ({
          ...prevDataFilter,
          ["justifyRequest"]: {
            type: "justifyRequest",
            data: data,
            rows: rowsTablejustifyRequest,
            headers: [
              t("recruitment:Folio"),
              t("recruitment:Applicant"),
              t("recruitment:type"),
              t("recruitment:status"),
            ],
          },
        }));
      } catch (error) {
        console.error(error);
      }
    };

    if (employees) {
      Justify();
    }

    const JobPositionsRequest = async () => {
      try {
        const { data } = await getJobPositionsRequest(user._id);

        const rowsTableJobPositionsRequest = data.map((obj) => ({
          id: obj?._id,
          folio: obj?.folio,
          applicant: employees[obj?.applicant],
          title: obj.title,
          quantity: obj.quantity,
          status: t(`recruitment:${obj.status}`),
        }));

        setDataFilter((prevDataFilter) => ({
          ...prevDataFilter,
          ["JobPositionsRequest"]: {
            type: "JobPositionsRequest",
            data: data,
            rows: rowsTableJobPositionsRequest,
            headers: [
              t("recruitment:Folio"),
              t("recruitment:Applicant"),
              t("recruitment:title"),
              t("recruitment:Vacancies"),
              t("recruitment:status"),
            ],
          },
        }));
      } catch (error) {
        console.error(error);
      }
    };

    if (employees) {
      JobPositionsRequest();
    }
  }, [employees, updateStaffs]);

  const handleClick = async (row) => {
    if (dataForTable?.data && dataForTable?.type === "staffRequests") {
      let data = dataForTable?.data.find((e) => row.id === e._id);
      await dispatch(setStaffRequest(data));
      setOpen(true);
    }

    if (dataForTable?.data && dataForTable?.type === "vacationsRequest") {
      let data = dataForTable?.data.find((e) => row.id === e._id);
      data = {
        ...data,
        startDate: dayjs.utc(data?.startDate),
        endDate: dayjs.utc(data?.endDate),
      };
      setVacationReques(data);
      setOpenVacationRequest(true);
    }

    if (dataForTable?.data && dataForTable?.type === "permissionsRequest") {
      let data = dataForTable?.data.find((e) => row.id === e._id);
      if (data?.endDate) {
        data = {
          ...data,
          startDate: dayjs.utc(data?.startDate),
          endDate: dayjs.utc(data?.endDate),
        };
      } else {
        data = {
          ...data,
          startDate: dayjs.utc(data?.startDate),
        };
      }
      setPermitsReques(data);
      setOpenPermitsReques(true);
    }

    if (dataForTable?.data && dataForTable?.type === "justifyRequest") {
      let data = dataForTable?.data.find((e) => row.id === e._id);
      setJustificationRequest(data);
      setOpenJustification(true);
    }

    if (dataForTable?.data && dataForTable?.type === "JobPositionsRequest") {
      let data = dataForTable?.data.find((e) => row.id === e._id);

      dispatch(setJobDescription(data));
      setPositionsRequest(data);
      setOpenPositions(true);
    }
  };

  return (
    <>
      <MenuRequests ubication={"Request"} />
      <br />
      <Box sx={{ width: { xs: "100%", sm: "90%" }, margin: "0 auto" }}>
        <Grid container spacing={4}>
          <Grid item={true} xs={12} md={9}>
            {dataForTable?.rows ? (
              <BasicTable
                PerPage={25}
                rows={dataForTable?.rows}
                handleClick={handleClick}
                customStyle={"StatusRequest"}
              >
                {dataForTable?.headers.map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </BasicTable>
            ) : (
              <Box
                p={2}
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Grid>
          <Grid item={true} xs={12} md={3}>
            {dataFilter?.staffRequests && (
              <Filtro dataFilter={dataFilter} firstKey={"staffRequests"} />
            )}
          </Grid>
        </Grid>
      </Box>

      <StaffRequestModal
        open={open}
        setOpen={setOpen}
        staffRequest={staffRequest}
        employees={employees}
      />
      <VacationRequestModal
        open={openVacationRequest}
        setOpen={setOpenVacationRequest}
        vacationReques={vacationReques}
        employees={employees}
      />
      <TypesOfPermitsModal
        open={openPermitsReques}
        setOpen={setOpenPermitsReques}
        permitsReques={permitsReques}
        employees={employees}
      />
      <JustifyModal
        openJustification={openJustification}
        setOpenJustification={setOpenJustification}
        justificationRequest={justificationRequest}
        employees={employees}
      />
      <PositionsModal
        openPositions={openPositions}
        setOpenPositions={setOpenPositions}
        positionRequest={positionsRequest}
        employees={employees}
      />
    </>
  );
};
