import { useTranslation } from "react-i18next";
import { UITextField, UIDateTimePicker, UIButton, Toast } from "components";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import {
  postScheduledPermissions,
  putScheduledPermissions,
  deleteScheduledPermissions,
} from "api";
import { useDispatch } from "react-redux";
import { setUpdateStaffs } from "@redux/slices/recruitment";

import * as Yup from "yup";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const today = dayjs().startOf("day");

export const VacationRequest = ({
  setOpen = null,
  request = null,
  btnsActions = true,
}) => {
  const { t } = useTranslation(["request"]["general"]);

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    startDate: Yup.date()
      .required(t("request:startDateRequired"))
      .min(today, t("request:startDateMin"))
      .test("is-before-endDate", t("request:startDateTest"), function (value) {
        const { endDate } = this.parent;
        return (
          !endDate ||
          dayjs(value).isBefore(dayjs(endDate)) ||
          dayjs(value).isSame(dayjs(endDate))
        );
      }),
    endDate: Yup.date().when("type", {
      is: (type) => !["LATE_ARRIVAL", "EARLY_DEPARTURE"].includes(type),
      then: (schema) => schema.required(t("request:endDateRequired")),
    }),
  });

  const onSubmit = async (values) => {
    const formattedValues = {
      ...values,
      startDate: values.startDate
        ? dayjs.utc(values.startDate).format("YYYY-MM-DDTHH:mm")
        : "",
      endDate: values.endDate
        ? dayjs.utc(values.endDate).format("YYYY-MM-DDTHH:mm")
        : "",
    };

    let message = "";

    try {
      if (values._id) {
        delete formattedValues?.createdAt;
        delete formattedValues?.files;
        delete formattedValues?.folio;
        delete formattedValues?.signStatus;
        delete formattedValues?.status;

        await putScheduledPermissions(values._id, formattedValues);
        message = t("request:ScheduledPermissionsSuccessfullyUpdate");
      } else {
        await postScheduledPermissions(formattedValues);
        message = t("request:ScheduledPermissionsSuccessfullyCreated");
      }
      dispatch(setUpdateStaffs(true));
      setOpen(false);
      Toast.fire({ icon: "success", title: message });
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: t("request:ScheduledPermissionsErrorSubmit"),
      });
      console.log("Error to submit form: ", e);
    }
  };

  const deleteItem = async (item) => {
    try {
      await deleteScheduledPermissions(item._id);
      dispatch(setUpdateStaffs(true));
      Toast.fire({
        icon: "success",
        title: t("request:ScheduledPermissionsSuccessfullyDelete"),
      });
    } catch (err) {
      console.log(err);
      Toast.fire({
        icon: "error",
        title: t("request:ScheduledPermissionsErrorDelete"),
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={
          request
            ? request
            : {
                type: "VACATION",
                comment: "",
                startDate: "",
                endDate: "",
              }
        }
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {(formik) => (
          <Form>
            <Typography variant="h5" mb={2}>
              {t("request:Solicituddevacaciones")}
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} md={4} lg={3}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.errors.startDate && formik.touched.startDate
                  )}
                >
                  <UIDateTimePicker
                    name="startDate"
                    customDisabled={"BeforeAndToday"}
                    label={t("request:startDate")}
                    disabled={formik.isSubmitting || !btnsActions}
                  />
                  <FormHelperText>
                    <ErrorMessage name="startDate" />
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item={true} xs={12} md={4} lg={3}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.errors.endDate && formik.touched.endDate
                  )}
                >
                  <UIDateTimePicker
                    name="endDate"
                    customDisabled={"BeforeAndToday"}
                    label={t("request:endDate")}
                    disabled={formik.isSubmitting || !btnsActions}
                  />
                  <FormHelperText>
                    <ErrorMessage name="endDate" />
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item={true} xs={12} md={8} lg={6}>
                <UITextField
                  label={t("request:Comentario")}
                  isrequired={false}
                  name={"comment"}
                  formik={formik}
                  disabled={formik.isSubmitting || !btnsActions}
                />
              </Grid>
            </Grid>
            {btnsActions && (
              <Box
                mt={2}
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                <Box display="flex" gap="1em">
                  <UIButton
                    type="submit"
                    label={
                      formik?.values?._id
                        ? t("general:Actualizar")
                        : t("general:Guardar")
                    }
                    loading={formik.isSubmitting}
                  />
                  <UIButton
                    label={t("general:Eliminar")}
                    onClick={() => {
                      setOpen(false);
                      Swal.fire({
                        title: t("general:Are"),
                        text: t("general:Youwon"),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: t("general:deleteit"),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          deleteItem(formik.values);
                        }
                      });
                    }}
                    disabled={formik.isSubmitting}
                  />
                </Box>
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};
