import { Widgets } from "modules/Widgets";
import { Bilding } from "components/Bilding";
import { PostMVP } from "components/PostMVP";
import { SearchAdvanced } from "modules/SearchAdvanced";
import { Profiles } from "modules/Employees/Profiles";
import { PersonalInformation } from "modules/Employees/Profiles/Collaborator/PersonalInformation";
import { ContactDetails } from "modules/Employees/Profiles/Collaborator/ContactDetails";
import { IdentitiesDocuments } from "modules/Employees/Profiles/Collaborator/IdentitiesDocuments";
import { RecordHistory } from "modules/Employees/Profiles/RecordHistory";
import { AcademyHistory } from "modules/Employees/Profiles/Competencies/AcademicHistory";
import { SkillsSummary } from "modules/Employees/Profiles/Competencies/SkillsSummary";
import { CurrentEmployment } from "modules/Employees/Profiles/EmploymentInformation/CurrentEmployment";
import { EmploymentSummary } from "modules/Employees/Profiles/EmploymentInformation/EmploymentsSummary";
import { Assets } from "modules/Employees/Profiles/Assets";
import { Documents } from "modules/Employees/Profiles/Documents";
import { Compensation } from "modules/Employees/Profiles/Compensation";
import { PhysicalAssets } from "modules/Assets/PhysicalAssets";
import { CompensationAndBenefits } from "modules/Organization/CompensationAndBenefits";
import { OrganizationSettings } from "modules/Organization/Settings";
import { Chart } from "modules/Organization/Chart";
import { Departments } from "modules/Organization/Departments";
import { Planes } from "modules/Organization/Settings/Planes";
import { ShiftAttendanceSettings } from "modules/ShiftAttendance/Settings";
import { TimeOff } from "modules/ShiftAttendance/Time-off";
import { Holidays } from "modules/ShiftAttendance/Time-off/Holidays";
import { Polices } from "modules/ShiftAttendance/AttendanceAbsence";
import { Policy } from "modules/ShiftAttendance/AttendanceAbsence/Policy";
import { WorkSchedules } from "modules/ShiftAttendance/WorkShiftSchedule";
import { WorkSchedule } from "modules/ShiftAttendance/WorkShiftSchedule/WorkSchedule";
import { Settings } from "modules/Settings/Security";
import { Tracking } from "modules/ShiftAttendance/Traking";
import { Configurations } from "modules/Settings/Configurations";
import { Import } from "modules/Settings/Import";
import { Templates } from "modules/Settings/Templates";
import { Locations } from "modules/Organization/Settings/Locations";
import { PageCatalogs } from "modules/Organization/Settings/PageCatalogs";
import { PageCurrentPlan } from "modules/Organization/Settings/PageCurrentPlan";
import { RedirectAccessSettings } from "modules/Settings/MenuNav/RedirectAccessSettings";
import { RedirectAccessOrganization } from "modules/Organization/Settings/RedirectAccessOrganization";
import { Dashboard } from "modules/Home/Dashboard";
import { JobsDescription } from "modules/Organization/JobDescription";
import { JobDescription } from "modules/Organization/JobDescription/JobDescription";
import { RecruitmentSettings } from "./app/modules/Recruitment/Settings";
import { StaffRequest } from "./app/modules/Recruitment/StaffRequests/StaffRequest";
import { Petitions } from "./app/modules/Requests/Petitions";
import { Signature } from "./app/modules/Requests/Signature";
import { RequestsHistory } from "./app/modules/Requests/RequestsHistory";
import { TimeClock } from "./app/modules/Employees/Profiles/TimeClock";
import { NotificationsCenter } from "./app/modules/NotificationsCenter";
import { JobPostings } from "./app/modules/Recruitment/JobPostings";
import { AttendanceReports } from "./app/modules/ShiftAttendance/AttendanceReports";
import { PresentationLetter } from "modules/Home/PresentationLetter";

export const HOME = [
  {
    name: "Home",
    route: "/cereza-organization/home/indicators",
    component: <Dashboard />,
  },
];

export const REQUESTS = [
  {
    name: "Requests",
    route: "/cereza-organization/requests",
    component: <Petitions />,
  },
];

export const ACCOUNT = [
  {
    name: "Ajustes",
    route: "/cereza-organization/home/account/configurations",
    component: <Bilding />,
  },
  {
    name: "GestiónDeUsuarios",
    route: "/cereza-organization/home/account/management",
    component: <Bilding />,
  },
  {
    name: "InformaciónDeEmpleado",
    route: "/cereza-organization/home/account/employee",
    component: <Bilding />,
  },
  {
    name: "Cuentas",
    route: "/cereza-organization/home/account/accounts",
    component: <Bilding />,
  },
  {
    name: "Notificaciones",
    route: "/cereza-organization/home/account/notifications",
    component: <Bilding />,
  },
  {
    name: "PlanesYFacturación",
    route: "/cereza-organization/home/account/plans-billing",
    component: <Bilding />,
  },
  {
    name: "HorariosLaborales",
    route: "/cereza-organization/home/account/working-hours",
    component: <Bilding />,
  },
];

export const ORGANIZATION = [
  {
    name: "Organigrama",
    route: "/cereza-organization/organization/chart",
    component: <Chart />,
  },
  {
    name: "departments",
    route: "/cereza-organization/organization/departments",
    component: <Departments />,
  },
  {
    name: "DesarrolloDeCarrera",
    route: "/cereza-organization/organization/career-development",
    component: <PostMVP />,
  },
  {
    name: "CompensaciónYBeneficios",
    route: "/cereza-organization/organization/compensation-benefits",
    component: <CompensationAndBenefits />,
  },
  {
    name: "EncuestaDeClimaLaboral",
    route: "/cereza-organization/organization/workplace-climate-survey",
    component: <PostMVP />,
  },
  {
    name: "ReglasDeClimaLaboral",
    route: "/cereza-organization/organization/workplace-climate-rules",
    component: <PostMVP />,
  },
  {
    name: "EncuestaDeCompromisoDeEmpleado",
    route: "/cereza-organization/organization/employee-engagement-survey",
    component: <PostMVP />,
  },
  {
    name: "ClimaLaboral",
    route: "/cereza-organization/organization/workplace-climate",
    component: <PostMVP />,
  },
  {
    name: "Integraciones",
    route: "/cereza-organization/organization/integrations",
    component: <Bilding />,
  },
  {
    name: "Tercero",
    route: "/cereza-organization/organization/third-party",
    component: <Bilding />,
  },
  {
    name: "JobsDescription",
    route: "/cereza-organization/organization/jobs-description",
    component: <JobsDescription />,
  },

  {
    name: "Ajustes",
    route: "/cereza-organization/organization/organization-configurations",
    component: <RedirectAccessOrganization />,
  },
];

export const RECRUITMENT = [
  {
    name: "Candidatos",
    route: "/cereza-organization/recruitment/candidates",
    component: <Bilding />,
  },
  {
    name: "OfertasDeTrabajo",
    route: "/cereza-organization/recruitment/postings",
    component: <JobPostings />,
  },
  {
    name: "Ajustes",
    route: "/cereza-organization/recruitment/recruitment-settings",
    component: <RecruitmentSettings />,
  },
];

export const EMPLOYEES = [
  {
    name: "Perfiles",
    route: "/cereza-organization/employees/profiles",
    component: <Profiles />,
  },
  {
    name: "MétricasYObjetivos",
    route: "/cereza-organization/employees/metrics-objectives",
    component: <Bilding />,
  },
  {
    name: "DiscapacidadesMédicas",
    route: "/cereza-organization/employees/medical-disabilities",
    component: <Bilding />,
  },
  {
    name: "Ajustes",
    route: "/cereza-organization/employees/employees-settings",
    component: <Bilding />,
  },
];

export const SHIFT_ATTENDANCE = [
  {
    name: "AsistenciaAusencia",
    route: "/cereza-organization/shift-attendance/attendance-absence",
    component: <AttendanceReports />,
  },
  {
    name: "Polices",
    route: "/cereza-organization/shift-attendance/polices",
    component: <Polices />,
  },
  {
    name: "VacacionesDeTiempoLibre",
    route: "/cereza-organization/shift-attendance/time-off-holidays",
    component: <TimeOff />,
  },
  {
    name: "HorarioDeTurnoDeTrabajoDeCereza",
    route: "/cereza-organization/shift-attendance/work-schedules",
    component: <WorkSchedules />,
  },
  {
    name: "Seguimiento",
    route: "/cereza-organization/shift-attendance/tracking",
    component: <Tracking />,
  },
  {
    name: "Ajustes",
    route: "/cereza-organization/shift-attendance/shift-attendance-settings",
    component: <ShiftAttendanceSettings />,
  },
];

export const LERNING_CENTER = [
  {
    name: "AcademiaCereza",
    route: "/cereza-organization/learning-center/cereza-academy",
    component: <Bilding />,
  },
  {
    name: "LearningHub",
    route: "/cereza-organization/learning-center/learning-hub",
    component: <Bilding />,
  },
  {
    name: "EvaluaciónDeCompetencias",
    route: "/cereza-organization/learning-center/competency-assessment",
    component: <PostMVP />,
  },
  {
    name: "Mercado",
    route: "/cereza-organization/learning-center/marketplace",
    component: <PostMVP />,
  },
  {
    name: "Ajustes",
    route: "/cereza-organization/learning-center/marketplace-settings",
    component: <Bilding />,
  },
];

export const PERFORMANCE_CENTER = [
  {
    name: "IndicadoresClave",
    route: "/cereza-organization/performance-center/key-indicators",
    component: <Bilding />,
  },
  {
    name: "OKRs",
    route: "/cereza-organization/performance-center/OKRs",
    component: <Bilding />,
  },
  {
    name: "Autoevaluaciones",
    route: "/cereza-organization/performance-center/self-Assessments",
    component: <PostMVP />,
  },
  {
    name: "MatrizDeHabilidades",
    route: "/cereza-organization/performance-center/skill-matrix",
    component: <PostMVP />,
  },
  {
    name: "Evaluaciones360",
    route: "/cereza-organization/performance-center/360-evaluations",
    component: <PostMVP />,
  },
  {
    name: "Ajustes",
    route:
      "/cereza-organization/performance-center/performance-center-settings",
    component: <Bilding />,
  },
];

export const ASSETS = [
  {
    name: "ActivosFisicos",
    route: "/cereza-organization/assets/physical-assets",
    component: <PhysicalAssets />,
  },
  {
    name: "ActivosVirtuales",
    route: "/cereza-organization/assets/virtual-assets",
    component: <PostMVP />,
  },
  {
    name: "Responsabilidades",
    route: "/cereza-organization/assets/responsibilities",
    component: <PostMVP />,
  },
  {
    name: "Ajustes",
    route: "/cereza-organization/assets/assets-settings",
    component: <Bilding />,
  },
];

export const RESOURCES = [
  {
    name: "Gestión",
    route: "/cereza-organization/resources/management",
    component: <Bilding />,
  },
  {
    name: "visitors",
    route: "/cereza-organization/resources/visitors",
    component: <Bilding />,
  },
  {
    name: "maintenance",
    route: "/cereza-organization/resources/maintenance",
    component: <PostMVP />,
  },
  {
    name: "Ajustes",
    route: "/cereza-organization/resources/maintenance-settings",
    component: <Bilding />,
  },
];

export const TOOLS = [
  {
    name: "Documentos",
    route: "/cereza-organization/tools/documents",
    component: <Bilding />,
  },
  {
    name: "Junta",
    route: "/cereza-organization/tools/board",
    component: <Bilding />,
  },
  {
    name: "InsigniaYLogro",
    route: "/cereza-organization/tools/badge-achievement",
    component: <Bilding />,
  },
  {
    name: "Boleto",
    route: "/cereza-organization/tools/tickets",
    component: <PostMVP />,
  },
  {
    name: "Ajustes",
    route: "/cereza-organization/tools/tickets-settings",
    component: <Bilding />,
  },
];

export const SETTINGS = [
  {
    name: "Configuraciones",
    route: "/cereza-organization/Configuraciones",
    component: <RedirectAccessSettings />,
  },
];

export const SPECIAL_ROUTES = [
  {
    name: "SearchAdvanced",
    route: "/cereza-organization/search/advanced",
    component: <SearchAdvanced />,
  },
  {
    name: "WidgetPage",
    route: "/cereza-organization/WidgetPage",
    component: <Widgets />,
  },
  {
    name: "Planes",
    route: "/cereza-organization/Planes",
    component: <Planes />,
  },
  {
    name: "Holidays",
    route: "/cereza-organization/shift-attendance/time-off-holidays/holidays",
    component: <Holidays />,
  },
  {
    name: "Policy",
    route: "/cereza-organization/shift-attendance/policy",
    component: <Policy />,
  },
  {
    name: "WorkSchedule",
    route: "/cereza-organization/shift-attendance/work-schedule",
    component: <WorkSchedule />,
  },
  {
    name: "UbicationsOrg",
    route:
      "/cereza-organization/organization/organization-settings/ubications-org",
    component: <Locations />,
  },
  {
    name: "Catalogos",
    route: "/cereza-organization/organization/organization-settings/catalogs",
    component: <PageCatalogs />,
  },
  {
    name: "Plan",
    route: "/cereza-organization/organization/organization-settings/plan",
    component: <PageCurrentPlan />,
  },
  {
    name: "Configuraciones",
    route: "/cereza-organization/settings/configurations",
    component: <Configurations />,
  },
  {
    name: "Templates",
    route: "/cereza-organization/settings/templates",
    component: <Templates />,
  },
  {
    name: "ImportExport",
    route: "/cereza-organization/settings/import-export",
    component: <Import />,
  },
  {
    name: "Security",
    route: "/cereza-organization/settings/security",
    component: <Settings />,
  },
  {
    name: "Ajustes",
    route: "/cereza-organization/organization/organization-settings",
    component: <OrganizationSettings />,
  },
  {
    name: "JobDescription",
    route: "/cereza-organization/organization/job-description",
    component: <JobDescription returnList={true} />,
  },
  {
    name: "StaffRequest",
    route: "/cereza-organization/recruitment/staff-requests",
    component: <StaffRequest />,
  },
  {
    name: "Signature",
    route: "/cereza-organization/requests/signature",
    component: <Signature />,
  },
  {
    name: "CreateRequests",
    route: "/cereza-organization/requests/history",
    component: <RequestsHistory />,
  },
  {
    name: "NotificationsCenter",
    route: "/cereza-organization/notifications/center",
    component: <NotificationsCenter />,
  },
  {
    name: "PresentationLetter",
    route: "/cereza-organization/presentation-letter",
    component: <PresentationLetter />,
  },
];

const PATH_PROFILE = "/cereza-organization/employees/profile";
const PATH_COLLABORATOR = `${PATH_PROFILE}/collaborator`;
const PATH_COMPETENCIES = `${PATH_PROFILE}/competencies`;
const PATH_EMPLOYMENT = `${PATH_PROFILE}/employment-information`;

export const PROFILE_MODULE = {
  Collaborator: {
    name: "Collaborator",
    sections: [
      {
        name: "PersonalInformation",
        route: `${PATH_COLLABORATOR}/personal-information`,
        component: <PersonalInformation />,
      },
      {
        name: "ContactDetails",
        route: `${PATH_COLLABORATOR}/contact-details`,
        component: <ContactDetails />,
      },
      {
        name: "IdentitiesDocuments",
        route: `${PATH_COLLABORATOR}/identities-documents`,
        component: <IdentitiesDocuments />,
      },
    ],
  },
  Competencies: {
    name: "Competencies",
    sections: [
      {
        name: "AcademyHistory",
        route: `${PATH_COMPETENCIES}/academy-history`,
        component: <AcademyHistory />,
      },
      {
        name: "SkillsSummary",
        route: `${PATH_COMPETENCIES}/skills-summary`,
        component: <SkillsSummary />,
      },
    ],
  },
  EmploymentInformation: {
    name: "EmploymentInformation",
    sections: [
      {
        name: "CurrentEmployment",
        route: `${PATH_EMPLOYMENT}/current-employment`,
        component: <CurrentEmployment />,
      },
      {
        name: "EmploymentSummary",
        route: `${PATH_EMPLOYMENT}/employment-summary`,
        component: <EmploymentSummary />,
      },
    ],
  },
  Compensation: {
    name: "Compensation",
    route: `${PATH_PROFILE}/compensation`,
    component: <Compensation />,
    sections: [],
  },
  TimeClock: {
    name: "TimeClock",
    route: `${PATH_PROFILE}/time-clocks`,
    component: <TimeClock />,
    sections: [],
  },
  Documents: {
    name: "Documents",
    route: `${PATH_PROFILE}/documents`,
    component: <Documents />,
    sections: [],
  },
  Assets: {
    name: "Assets",
    route: `${PATH_PROFILE}/assets`,
    component: <Assets />,
    sections: [],
  },
  RecordHistory: {
    name: "RecordHistory",
    route: `${PATH_PROFILE}/record-history`,
    component: <RecordHistory />,
    sections: [],
  },
};
