import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { Toast, UIButton } from "components";
import { useTranslation } from "react-i18next";
import { postVacancyReasons } from "api";
import { useDispatch } from "react-redux";
import { FormVacancyReason } from "./FormVacancyReason";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const ModalVacancyReason = (
  { open, setOpen, vancancyReasons, setVancancyReasons },
) => {
  const { t } = useTranslation(["recruitment"]["general"]);
  const dispatch = useDispatch();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const { data } = await postVacancyReasons(values);

      dispatch(
        setVancancyReasons([...vancancyReasons, {
          value: data._id,
          label: data.name,
        }]),
      );
      setOpen(false);

      resetForm();
      Toast.fire({
        icon: "success",
        title: t("recruitment:VancancyReasonSuccessfullyCreated"),
      });
    } catch (err) {
      console.log(err);
      setOpen(false);
      Toast.fire({
        icon: "error",
        title: err.messages,
      });
    }
  };

  return (
    <BootstrapDialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={() => setOpen(false)}
      open={open}
    >
      <DialogTitle>{t("recruitment:VancancyReason")}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Formik
          initialValues={{
            name: "",
          }}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <FormVacancyReason formik={formik} />
              <Box
                mt={2}
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                <Box display="flex" gap="1em">
                  <UIButton
                    type="submit"
                    label={formik?.values?._id
                      ? t("general:Actualizar")
                      : t("general:Guardar")}
                    loading={formik.isSubmitting}
                  />
                  {!formik.isSubmitting && (
                    <UIButton
                      type="button"
                      label={t("general:Cancel")}
                      onClick={() => {
                        setOpen(false);
                      }}
                      loading={formik.isSubmitting}
                    />
                  )}
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </BootstrapDialog>
  );
};
