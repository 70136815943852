// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Box-btn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  width: fit-content;
}

.btn-option {
  display: grid;
  place-items: center;
  grid-template-rows: 75% 25%;

  border: 2px solid #1675e0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;

  height: 200px;
  max-width: 300px;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
}

.btn-option:hover {
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.2),
    0 2px 12px rgba(0, 0, 0, 0.19);

  border: 2px solid #34c3ff;
}

@media only screen and (max-width: 768px) {
  .Box-btn {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
}
`, "",{"version":3,"sources":["webpack://./../cereza-organization/src/app/modules/Recruitment/StaffRequests/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;;EAE3B,yBAAyB;EACzB,mBAAmB;EACnB,2BAA2B;EAC3B,wBAAwB;EACxB,uBAAuB;EACvB,sBAAsB;;EAEtB,aAAa;EACb,gBAAgB;EAChB,aAAa;EACb,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE;;kCAEgC;;EAEhC,yBAAyB;AAC3B;;AAEA;EACE;IACE,0BAA0B;IAC1B,2BAA2B;EAC7B;AACF","sourcesContent":[".Box-btn {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 2rem;\n  width: fit-content;\n}\n\n.btn-option {\n  display: grid;\n  place-items: center;\n  grid-template-rows: 75% 25%;\n\n  border: 2px solid #1675e0;\n  border-radius: 10px;\n  -webkit-border-radius: 10px;\n  -moz-border-radius: 10px;\n  -ms-border-radius: 10px;\n  -o-border-radius: 10px;\n\n  height: 200px;\n  max-width: 300px;\n  padding: 1rem;\n  text-align: center;\n  cursor: pointer;\n}\n\n.btn-option:hover {\n  box-shadow:\n    0 4px 8px rgba(0, 0, 0, 0.2),\n    0 2px 12px rgba(0, 0, 0, 0.19);\n\n  border: 2px solid #34c3ff;\n}\n\n@media only screen and (max-width: 768px) {\n  .Box-btn {\n    grid-template-columns: 1fr;\n    grid-template-rows: 1fr 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
