import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  UIButton,
  UISelect,
  UISelectCheckmarks,
  UITextField,
} from "components";
import { AddCircleOutline as AddCircleOutlineIcon } from "@mui/icons-material";
import { ModalVacancyReason } from "./ModalVacancyReason";
import { useState } from "react";
import {
  recruitmentSelect,
  setVacancyReasons,
} from "@redux/slices/recruitment";
import { useSelector } from "react-redux";
import { Description } from "./Description";

function replaceValueWithId(array) {
  return array.map((obj) => {
    const { value, ...rest } = obj;
    return { _id: value, ...rest };
  });
}

export const FormStaffRequest = ({
  formik,
  canAdd,
  canChange,
  canAddVacancyReasons,
  jobsProfile,
  btnsActions = true,
  tipeForm = null,
}) => {
  const { t } = useTranslation(["recruitment"]["general"]);

  const [open, setOpen] = useState(false);
  const { vacancyReasons } = useSelector(recruitmentSelect);

  const disabled =
    (!canAdd && formik.values?._id === undefined) ||
    (!canChange && formik.values?._id !== undefined) ||
    formik.isSubmitting;

  const vacancies = replaceValueWithId(vacancyReasons);

  const job = jobsProfile.find((obj) => obj._id === formik.values?.jobPosition);

  const rules = (job) => {
    if (job) {
      return job?.quantity - job?.occupiedPositions;
    } else {
      return 2;
    }
  };

  const disponibles = job?.quantity - job?.occupiedPositions;

  return (
    <>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12} sm={12} md={6} lg={4}>
          <UISelect
            label={t("recruitment:JobProfile")}
            name="jobPosition"
            formik={formik}
            options={jobsProfile}
            disabled={disabled || !btnsActions}
            isrequired={true}
          />
          {tipeForm === "AumentarVacantesPuesto" && (
            <Description
              job={{
                quantity: job?.quantity,
                occupiedPositions: job?.occupiedPositions,
                disponibles: disponibles ? disponibles : 0,
              }}
            />
          )}
        </Grid>
        <Grid item={true} xs={12} sm={12} md={6} lg={3}>
          <UITextField
            label={
              tipeForm === "AumentarVacantesPuesto"
                ? t("recruitment:VacanciesPlus")
                : t("recruitment:Vacancies")
            }
            name="vacancies"
            formik={formik}
            disabled={disabled || !btnsActions || !job}
            isrequired={true}
            type="number"
            InputProps={{
              inputProps: {
                min: 1,
                max: tipeForm === "AumentarVacantesPuesto" ? 99 : rules(job),
              },
            }}
          />
        </Grid>
        <Grid item={true} xs={12} sm={12} md={6} lg={5}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs>
              <UISelectCheckmarks
                label={
                  tipeForm === "AumentarVacantesPuesto"
                    ? t("recruitment:VacancyReasonPlus")
                    : t("recruitment:VacancyReason")
                }
                name={"vacancyReason"}
                formik={formik}
                options={vacancies}
                disabled={disabled || !btnsActions}
                fieldRender={"label"}
                isrequired="true"
              />
            </Grid>
            {canAddVacancyReasons && btnsActions && (
              <Grid item={true} xs={3}>
                <UIButton
                  sx={{
                    height: "100%",
                  }}
                  onClick={() => setOpen(true)}
                >
                  <AddCircleOutlineIcon />
                </UIButton>
                <ModalVacancyReason
                  open={open}
                  setOpen={setOpen}
                  vancancyReasons={vacancyReasons}
                  setVancancyReasons={setVacancyReasons}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
