import {
  Box,
  Chip,
  Grid,
  Tooltip,
  TableCell,
  TextField,
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Toast, UIButton, BasicTable, UIDatePicker } from "components";
import { Formik, Form } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getGeneralReports, getAttendance } from "api";
import { useSelector } from "react-redux";
import { authUserSelect } from "@redux/slices/authUser";
import { ModalJustify } from "./ModalJustify";
import { convertUTCToLocal } from "helpers";

import HeightIcon from "@mui/icons-material/Height";
import dayjs from "dayjs";

const yesterday = dayjs().subtract(1, "day").format("YYYY-MM-DD");

export const AttendanceReports = () => {
  const { t } = useTranslation(["shiftAttendance"]["general"]);

  const { user } = useSelector(authUserSelect);

  const [alignment, setAlignment] = useState("all");
  const [reports, setReports] = useState([]);
  const [optionsSelector, setOptionsSelector] = useState([]);
  const [filter, setFilter] = useState([]);
  const [sorting, setSorting] = useState("EMPLOYEE");
  const [value, setValue] = useState([]);
  const [open, setOpen] = useState(false);
  const [report, setReport] = useState({});

  const handleChangeSort = (event) => {
    setSorting(event);

    let ordenar = [];

    const currently = filter.length === 0 ? reports : filter;

    if (event === "EMPLOYEE") {
      ordenar = currently.sort((a, b) => {
        if (a.fullName < b.fullName) {
          return -1;
        }
        if (a.fullName > b.fullName) {
          return 1;
        }
        return 0;
      });
      setFilter(ordenar);
    }

    if (event === "DATE") {
      ordenar = currently.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
      });
      setFilter(ordenar);
    }
  };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleSubmit = async ({ dateFrom, dateTo }) => {
    setOptionsSelector([]);
    setFilter([]);
    setReports([]);
    setValue([]);
    setSorting("EMPLOYEE");

    if (alignment === "all") {
      try {
        const { data } = await getGeneralReports(dateFrom, dateTo);

        const options = Object.values(data)
          .flat()
          .map((obj) => ({
            _id: obj?.employee,
            name: obj?.fullName,
          }));

        const uniqueOptionsMap = new Map();
        options.forEach((option) => {
          uniqueOptionsMap.set(option._id, option);
        });

        const uniqueOptions = Array.from(uniqueOptionsMap.values()).sort(
          (a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          }
        );
        setOptionsSelector(uniqueOptions);

        const monoList = Object.values(data)
          .flat()
          .map((obj) => ({
            id: obj?._id,
            thumbnail: obj?.thumbnail,
            employee: obj?.employee,
            fullName: obj?.fullName,
            date: obj?.date,
            checkIn: obj?.checkIn
              ? convertUTCToLocal(obj?.checkIn).format("YYYY-MM-DD HH:mm")
              : "",
            realCheckIn: obj?.realCheckIn
              ? convertUTCToLocal(obj?.realCheckIn).format("YYYY-MM-DD HH:mm")
              : "",
            differenceCheckIn: obj?.differenceCheckIn,
            checkInDelay: obj?.checkInDelay,
            checkOut: obj?.checkOut
              ? convertUTCToLocal(obj?.checkOut).format("YYYY-MM-DD HH:mm")
              : "",
            realCheckOut: obj?.realCheckOut
              ? convertUTCToLocal(obj?.realCheckOut).format("YYYY-MM-DD HH:mm")
              : "",
            differenceCheckOut: obj?.differenceCheckOut,
            workedHours: obj?.workedHours,
            timeIn: obj?.timeIn,
            timeOut: obj?.timeOut,
            assistance: t(`recruitment:${obj?.assistance}`),
            checks: obj?.checks,
            country: obj?.country,
            lunchStart: obj?.lunchStart
              ? convertUTCToLocal(obj?.lunchStart).format("YYYY-MM-DD HH:mm")
              : "",
            lunchEnd: obj?.lunchEnd
              ? convertUTCToLocal(obj?.lunchEnd).format("YYYY-MM-DD HH:mm")
              : "",
            lunchTime: obj?.lunchTime,
            policy: obj?.policy,
            schedule: obj?.schedule,
            assistanceColor: obj?.assistance,
          }))
          .sort((a, b) => {
            if (a.fullName < b.fullName) {
              return -1;
            }
            if (a.fullName > b.fullName) {
              return 1;
            }
            return 0;
          });
        setReports(monoList);
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: t("recruitment:ErrorAlIntentarLaAccion"),
        });
        console.log("error: ", error);
      }
    }

    if (alignment === "onlyMy") {
      try {
        const { data } = await getAttendance(user?._id, dateFrom, dateTo);

        const monoList = Object.values(data)
          .flat()
          .map((obj) => ({
            id: obj?._id,
            thumbnail: obj?.thumbnail,
            employee: obj?.employee,
            fullName: obj?.fullName,
            date: obj?.date,
            checkIn: obj?.checkIn
              ? convertUTCToLocal(obj?.checkIn).format("YYYY-MM-DD HH:mm")
              : "",
            realCheckIn: obj?.realCheckIn
              ? convertUTCToLocal(obj?.realCheckIn).format("YYYY-MM-DD HH:mm")
              : "",
            differenceCheckIn: obj?.differenceCheckIn,
            checkInDelay: obj?.checkInDelay,
            checkOut: obj?.checkOut
              ? convertUTCToLocal(obj?.checkOut).format("YYYY-MM-DD HH:mm")
              : "",
            realCheckOut: obj?.realCheckOut
              ? convertUTCToLocal(obj?.realCheckOut).format("YYYY-MM-DD HH:mm")
              : "",
            differenceCheckOut: obj?.differenceCheckOut,
            workedHours: obj?.workedHours,
            timeIn: obj?.timeIn,
            timeOut: obj?.timeOut,
            assistance: t(`recruitment:${obj?.assistance}`),
            checks: obj?.checks,
            country: obj?.country,
            lunchStart: obj?.lunchStart
              ? convertUTCToLocal(obj?.lunchStart).format("YYYY-MM-DD HH:mm")
              : "",
            lunchEnd: obj?.lunchEnd
              ? convertUTCToLocal(obj?.lunchEnd).format("YYYY-MM-DD HH:mm")
              : "",
            lunchTime: obj?.lunchTime,
            policy: obj?.policy,
            schedule: obj?.schedule,
            assistanceColor: obj?.assistance,
          }))
          .sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB - dateA;
          });
        setReports(monoList);
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: t("recruitment:ErrorAlIntentarLaAccion"),
        });
        console.log("error: ", error);
      }
    }
  };

  const handleSubmitFilter = (values) => {
    const ids = values.map((item) => item?._id);
    let dataFilter = reports.filter((obj) => ids?.includes(obj.employee));
    setFilter(dataFilter);
  };

  const handleClickAssign = (row) => {
    setOpen(true);
    setReport(row);
  };

  return (
    <>
      <Box>
        <Formik
          initialValues={{
            dateFrom: yesterday,
            dateTo: yesterday,
          }}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form id="d" autoComplete="off">
              <Grid container spacing={2}>
                <Grid item={true} xs={12} md={6} lg={4}>
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                    sx={{ height: "100%" }}
                  >
                    <ToggleButton value="all" disabled={formik.isSubmitting}>
                      {t("shiftAttendance:VerTodo")}
                    </ToggleButton>
                    <ToggleButton value="onlyMy" disabled={formik.isSubmitting}>
                      {t("shiftAttendance:MisAsistencias")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item={true} xs={12} md={6} lg={2}>
                  <UIDatePicker
                    formik={formik}
                    label={t("shiftAttendance:DíaInicio")}
                    name="dateFrom"
                    customDisabled={"After"}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6} lg={2}>
                  <UIDatePicker
                    formik={formik}
                    label={t("shiftAttendance:DíaFin")}
                    name="dateTo"
                    customDisabled={"After"}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item={true} xs={12} md={3} lg={2}>
                  <UIButton
                    type="submit"
                    label={t("general:Buscar")}
                    style={{ height: "56px" }}
                    loading={formik.isSubmitting}
                  />
                </Grid>
                <Grid item={true} xs={12} md={3} lg={2}>
                  <UIButton
                    label={t("general:Exportar")}
                    style={{ height: "56px" }}
                    loading={formik.isSubmitting}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        <hr />

        {alignment === "all" && (
          <Grid container spacing={2}>
            <Grid item={true} xs={12} lg={8}>
              <Autocomplete
                options={optionsSelector}
                getOptionLabel={(option) => option.name}
                value={value}
                multiple={true}
                isOptionEqualToValue={(option, value) =>
                  option?._id === value?._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("shiftAttendance:FiltroEmpleados")}
                  />
                )}
                onChange={(event, newValue) => {
                  handleSubmitFilter(newValue);
                  setValue(newValue);
                }}
                renderTags={(selected, getTagProps) =>
                  selected.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                      <Chip
                        key={option._id}
                        label={option.name}
                        {...tagProps}
                      />
                    );
                  })
                }
              />
            </Grid>
          </Grid>
        )}

        <br />
        <BasicTable
          rows={filter.length === 0 ? reports : filter}
          handleClick={handleClickAssign}
          customStyle={"AttendanceReports"}
          PerPage={25}
          color={false}
          dense={true}
          dontShow={[
            "lunchStart",
            "lunchEnd",
            "lunchTime",
            "checks",
            "employee",
            "country",
            "policy",
            "schedule",
            "timeIn",
            "timeOut",
            "assistanceColor",
          ]}
        >
          <TableCell sx={{ padding: "6px" }}></TableCell>
          <TableCell>
            <Tooltip title={t("general:Ordenar")}>
              <Box
                sx={{ display: "flex", cursor: "pointer" }}
                onClick={() => {
                  handleChangeSort("EMPLOYEE");
                }}
              >
                {t("shiftAttendance:Empleado")}
                <HeightIcon
                  sx={{ opacity: "1", color: "rgba(0, 0, 0, 0.6)" }}
                />
              </Box>
            </Tooltip>
          </TableCell>
          <TableCell sx={{ padding: "6px" }}>
            <Tooltip title={t("general:Ordenar")}>
              <Box
                sx={{ display: "flex", cursor: "pointer" }}
                onClick={() => {
                  handleChangeSort("DATE");
                }}
              >
                {t("shiftAttendance:Fecha")}
                <HeightIcon
                  sx={{ opacity: "1", color: "rgba(0, 0, 0, 0.6)" }}
                />
              </Box>
            </Tooltip>
          </TableCell>
          <TableCell sx={{ padding: "6px" }}>
            {t("shiftAttendance:Entrada")}
          </TableCell>
          <TableCell sx={{ padding: "6px" }}>
            {t("shiftAttendance:EntradaReal")}
          </TableCell>
          <TableCell sx={{ padding: "6px" }}>
            {t("shiftAttendance:DiferenciaEntrada(Min)")}
          </TableCell>
          <TableCell sx={{ padding: "6px" }}>
            {t("shiftAttendance:Retardo")}
          </TableCell>
          <TableCell sx={{ padding: "6px" }}>
            {t("shiftAttendance:HoraSalida")}
          </TableCell>
          <TableCell sx={{ padding: "6px" }}>
            {t("shiftAttendance:SalidaReal")}
          </TableCell>
          <TableCell sx={{ padding: "6px" }}>
            {t("shiftAttendance:DiferenciaSalida(Min)")}
          </TableCell>
          <TableCell sx={{ padding: "6px" }}>
            {t("shiftAttendance:Laborado")}
          </TableCell>
          <TableCell sx={{ padding: "6px" }}>
            {t("shiftAttendance:Asistencia")}
          </TableCell>
        </BasicTable>
      </Box>
      <ModalJustify open={open} setOpen={setOpen} report={report} />
    </>
  );
};
