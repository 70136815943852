import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  UITextField,
  UIDateTimePicker,
  UIMultipleFileInput,
  UIButton,
  UISelect,
  Toast,
} from "components";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import {
  postScheduledPermissions,
  putScheduledPermissions,
  deleteScheduledPermissions,
  getFiles,
} from "api";
import { tenantSettingsSelect } from "@redux/slices/tenant";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUpdateStaffs } from "@redux/slices/recruitment";

import * as Yup from "yup";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const today = dayjs().startOf("day");

export const TypesOfPermits = ({
  setOpen = null,
  request = null,
  btnsActions = null,
}) => {
  const { t } = useTranslation(["request"]);

  const dispatch = useDispatch();

  const { tenant } = useSelector(tenantSettingsSelect);

  const validationSchema = Yup.object().shape({
    type: Yup.string().required(t("request:typeRequired")),
    comment: Yup.string().when("type", {
      is: (type) =>
        ["DAY_OFF", "TIME_OFF", "LATE_ARRIVAL", "EARLY_DEPARTURE"].includes(
          type
        ),
      then: (schema) => schema.required(t("request:commentRequired")),
    }),
    files: Yup.mixed().when("type", {
      is: "MEDICAL",
      then: (schema) => schema.required(t("request:filesRequired")),
    }),
    startDate: Yup.date()
      .required(t("request:startDateRequired"))
      .min(today, t("request:startDateMin"))
      .test("is-before-endDate", t("request:startDateTest"), function (value) {
        const { endDate } = this.parent;
        return (
          !endDate ||
          dayjs(value).isBefore(dayjs(endDate)) ||
          dayjs(value).isSame(dayjs(endDate))
        );
      }),
    endDate: Yup.date().when("type", {
      is: (type) => !["LATE_ARRIVAL", "EARLY_DEPARTURE"].includes(type),
      then: (schema) => schema.required(t("request:endDateRequired")),
    }),
  });

  const [photos, setPhotos] = useState([]);

  async function fetchImagesInBase64(tenantId, fileNames) {
    if (!Array.isArray(fileNames)) {
      throw new Error("El argumento fileNames debe ser un array.");
    }

    if (fileNames.length === 0) {
      return [];
    }

    const requests = fileNames.map((fileName) => {
      return getFiles(tenantId, fileName)
        .then((response) => {
          return response.data.content;
        })
        .catch((error) => {
          console.error(`Error al obtener la imagen para ${fileName}:`, error);
          return null;
        });
    });

    const images = await Promise.all(requests);

    return images.filter((image) => image !== null);
  }

  useEffect(() => {
    if (request?.files.length > 0) {
      fetchImagesInBase64(tenant._id, request?.files)
        .then((images) => {
          setPhotos(images);
        })
        .catch((error) => {
          console.error("Error al obtener las imágenes:", error);
        });
    }
  }, []);

  const onSubmit = async (values) => {
    const formattedValues = {
      ...values,
      files: photos,
      startDate: values.startDate
        ? dayjs.utc(values.startDate).format("YYYY-MM-DDTHH:mm")
        : "",
      endDate: values.endDate
        ? dayjs.utc(values.endDate).format("YYYY-MM-DDTHH:mm")
        : "",
    };

    let message = "";

    try {
      if (values._id) {
        delete formattedValues?.createdAt;
        delete formattedValues?.folio;
        delete formattedValues?.signStatus;
        delete formattedValues?.status;
        if (
          formattedValues?.type === "EARLY_DEPARTURE" ||
          formattedValues?.type === "LATE_ARRIVAL"
        ) {
          delete formattedValues?.endDate;
        }
        await putScheduledPermissions(values._id, formattedValues);
        message = t("request:ScheduledPermissionsSuccessfullyUpdate");
      } else {
        if (
          formattedValues?.type === "EARLY_DEPARTURE" ||
          formattedValues?.type === "LATE_ARRIVAL"
        ) {
          delete formattedValues?.endDate;
        }
        await postScheduledPermissions(formattedValues);
        message = t("request:ScheduledPermissionsSuccessfullyCreated");
      }
      dispatch(setUpdateStaffs(true));
      setOpen(false);
      Toast.fire({ icon: "success", title: message });
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: t("request:ScheduledPermissionsErrorSubmit"),
      });
      console.log("Error to submit form: ", e);
    }
  };

  const deleteItem = async (item) => {
    try {
      await deleteScheduledPermissions(item._id);
      dispatch(setUpdateStaffs(true));
      Toast.fire({
        icon: "success",
        title: t("request:ScheduledPermissionsSuccessfullyDelete"),
      });
    } catch (err) {
      console.log(err);
      Toast.fire({
        icon: "error",
        title: t("request:ScheduledPermissionsErrorDelete"),
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={
          request
            ? request
            : {
                type: "",
                comment: "",
                files: [],
                startDate: "",
                endDate: "",
              }
        }
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {(formik) => (
          <Form>
            <Typography variant="h5" mb={2}>
              {t("request:Solicituddepermisos")}
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} md={4} lg={3}>
                <FormControl
                  fullWidth
                  error={Boolean(formik.errors.type && formik.touched.type)}
                >
                  <UISelect
                    formik={formik}
                    label={t("request:TipoDePermiso")}
                    name={"type"}
                    disabled={formik.isSubmitting || !btnsActions}
                    options={[
                      { value: "DAY_OFF", label: t("request:DAY_OFF") },
                      { value: "TIME_OFF", label: t("request:TIME_OFF") },
                      { value: "MEDICAL", label: t("request:MEDICAL") },
                      {
                        value: "LATE_ARRIVAL",
                        label: t("request:LATE_ARRIVAL"),
                      },
                      {
                        value: "EARLY_DEPARTURE",
                        label: t("request:EARLY_DEPARTURE"),
                      },
                    ]}
                  />
                  <FormHelperText>
                    <ErrorMessage name="type" />
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item={true} xs={12} md={4} lg={3}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.errors.startDate && formik.touched.startDate
                  )}
                >
                  <UIDateTimePicker
                    name="startDate"
                    customDisabled={"BeforeAndToday"}
                    label={t("request:startDate")}
                    disabled={formik.isSubmitting || !btnsActions}
                  />
                  <FormHelperText>
                    <ErrorMessage name="startDate" />
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item={true} xs={12} md={4} lg={3}>
                {formik?.values?.type !== "LATE_ARRIVAL" &&
                  formik?.values?.type !== "EARLY_DEPARTURE" && (
                    <FormControl
                      fullWidth
                      error={Boolean(
                        formik.errors.endDate && formik.touched.endDate
                      )}
                    >
                      <UIDateTimePicker
                        name="endDate"
                        customDisabled={"BeforeAndToday"}
                        label={t("request:endDate")}
                        disabled={formik.isSubmitting || !btnsActions}
                      />
                      <FormHelperText>
                        <ErrorMessage name="endDate" />
                      </FormHelperText>
                    </FormControl>
                  )}
              </Grid>
              <Grid item={true} xs={12} md={12} lg={9}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.errors.comment && formik.touched.comment
                  )}
                >
                  <UITextField
                    label={t("request:Comentario")}
                    isrequired={false}
                    name={"comment"}
                    formik={formik}
                    disabled={formik.isSubmitting || !btnsActions}
                    error={Boolean(
                      formik.errors.comment && formik.touched.comment
                    )}
                    helperText={
                      formik.errors.comment && formik.touched.comment
                        ? formik.errors.comment
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item={true} xs={12} md={12} lg={9}>
                <FormControl
                  fullWidth
                  error={Boolean(formik.errors.files && formik.touched.files)}
                >
                  <UIMultipleFileInput
                    formik={formik}
                    photos={photos}
                    setPhotos={setPhotos}
                    label={t("request:CargarArchivos")}
                    disabled={formik.isSubmitting || !btnsActions}
                  />
                  <FormHelperText>
                    <ErrorMessage name="files" />
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            {btnsActions && (
              <Box
                mt={2}
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                <Box display="flex" gap="1em">
                  <UIButton
                    type="submit"
                    label={
                      formik?.values?._id
                        ? t("general:Actualizar")
                        : t("general:Guardar")
                    }
                    loading={formik.isSubmitting}
                  />
                  <UIButton
                    label={t("general:Eliminar")}
                    onClick={() => {
                      setOpen(false);
                      Swal.fire({
                        title: t("general:Are"),
                        text: t("general:Youwon"),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: t("general:deleteit"),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          deleteItem(formik.values);
                        }
                      });
                    }}
                    disabled={formik.isSubmitting}
                  />
                </Box>
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};
