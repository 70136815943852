import { useEffect } from "react";
import TemplateGridUI from "utils/Template";
import { Bilding } from "components/Bilding";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  ACCOUNT,
  ASSETS,
  EMPLOYEES,
  HOME,
  LERNING_CENTER,
  ORGANIZATION,
  PERFORMANCE_CENTER,
  PROFILE_MODULE,
  RECRUITMENT,
  REQUESTS,
  RESOURCES,
  SETTINGS,
  SHIFT_ATTENDANCE,
  SPECIAL_ROUTES,
  TOOLS,
} from "./routes";
import { authUserSelect } from "@redux/slices/authUser";
import { uiSelect } from "@redux/slices/ui";
import { useSelector } from "react-redux";
import { parameterizationSelect } from "@redux/slices/parameterization";
import { createTheme, ThemeProvider } from "@mui/material";

import { CustomProvider } from "rsuite";

import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import "dayjs/locale/pt";
import updateLocale from "dayjs/plugin/updateLocale";
dayjs.extend(updateLocale);
import "./App.css";

export const unrestrictedRoutes = [
  "/cereza-organization/requests",
  "/cereza-organization/requests/signature",
  "/cereza-organization/requests/history",
  "/cereza-organization/notifications/center",
];

const App = () => {
  const { accessToken, keycloakProfile, permissions } =
    useSelector(authUserSelect);
  const { mainColor, mode } = useSelector(parameterizationSelect);
  const { preferences } = useSelector(uiSelect);

  const theme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: mainColor,
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            zIndex: 1059,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            zIndex: 1059,
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            zIndex: 1060,
          },
        },
      },
    },
  });

  const routesGenerator = (module) => {
    return module.map(({ route, component }, index) => {
      if (
        keycloakProfile?.is_superuser ||
        permissions?.paths.includes(route) ||
        unrestrictedRoutes.includes(route)
      ) {
        return <Route key={index} path={route} element={component} />;
      }
    });
  };

  const routesModule = (modules) => {
    let routes = [];

    Object.keys(modules).forEach((key) => {
      const module = modules[key];

      if (module.hasOwnProperty("sections") && module.sections.length) {
        routes = routes.concat(routesGenerator(module.sections));
      } else {
        routes = routes.concat(routesGenerator([module]));
      }
    });

    return routes;
  };

  useEffect(() => {
    const locale = preferences?.language?.abb;
    switch (locale) {
      case "es":
        dayjs.locale("es");
        dayjs.updateLocale("es", { weekStart: 0 });
        return dayjs;

      case "fr":
        dayjs.locale("fr");
        dayjs.updateLocale("fr", { weekStart: 0 });
        return dayjs;

      case "pt":
        dayjs.locale("pt");
        dayjs.updateLocale("pt", { weekStart: 0 });
        return dayjs;

      default:
        return dayjs.locale("en");
    }
  }, [preferences?.language?.abb]);

  return (
    <CustomProvider theme={mode}>
      <ThemeProvider theme={theme}>
        <TemplateGridUI>
          {accessToken !== null ? (
            <Routes>
              {keycloakProfile?.is_superuser ||
              permissions?.apps?.organization === true ? (
                <>
                  <Route path="/cereza-organization" element={<Bilding />} />
                  {routesGenerator(HOME)}
                  {/* {routesGenerator(ACCOUNT)} */}
                  {routesGenerator(REQUESTS)}
                  {routesGenerator(ORGANIZATION)}
                  {routesGenerator(RECRUITMENT)}
                  {routesGenerator(EMPLOYEES)}
                  {routesGenerator(SHIFT_ATTENDANCE)}
                  {/* {routesGenerator(LERNING_CENTER)} */}
                  {/* {routesGenerator(PERFORMANCE_CENTER)} */}
                  {/* {routesGenerator(ASSETS)} */}
                  {/* {routesGenerator(RESOURCES)} */}
                  {/* {routesGenerator(TOOLS)} */}
                  {routesGenerator(SETTINGS)}
                  {routesGenerator(SPECIAL_ROUTES)}
                  {routesModule(PROFILE_MODULE)}
                </>
              ) : (
                <Route path="*" element={<Navigate to="/auth/not" />} />
              )}
            </Routes>
          ) : (
            <Routes>
              <Route path="*" element={<Navigate to="/auth/login" />} />
            </Routes>
          )}
        </TemplateGridUI>
      </ThemeProvider>
    </CustomProvider>
  );
};

export default App;
