import { useEffect, useState } from "react";
import { BasicTable, Toast } from "components";
import { Box, Paper, TableCell, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LocationsForm } from "./LocationsForm";
import { Form, Formik } from "formik";
import { getLocations, postLocation, putLocation } from "api";
import { useDispatch } from "react-redux";
import { setLocations } from "@redux/slices/general";
import { MenuNav } from "./MenuNav";

export const Locations = ({ showTabs = true, setUpdateWizard = null }) => {
  const { t } = useTranslation(["organization"]["general"]);
  const dispatcher = useDispatch();

  const [rows, setRows] = useState([]);
  const [location, setLocation] = useState(null);
  const [ubications, setUbications] = useState([]);
  const [initialValues, setInitialValues] = useState(null);

  const handleClick = (row) => {
    const myUbication = ubications.find(
      (ubications) => ubications._id === row.id
    );
    const { createdAt, updatedAt, tenant, ...rest } = myUbication;
    setInitialValues(rest);
  };

  const handleSubmit = async (values) => {
    try {
      if (values?._id !== undefined) {
        const { data } = await putLocation(values, values._id);
        setLocation(data);
      } else {
        const { data } = await postLocation(values);
        setLocation(data);
      }

      if (setUpdateWizard) {
        setUpdateWizard(Math.random());
      }

      Toast.fire({
        icon: "success",
        title: t("general:AcciónExitosa"),
      });
    } catch (error) {
      managerErrors(error?.response?.data?.message);
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const { data } = await getLocations();
        setUbications(data);

        const rows = data.map((row) => ({
          id: row._id,
          name: row.name,
          address: row.address,
          zipCode: row.zipCode,
        }));
        setRows(rows);

        const selectors = data.map((row) => ({
          value: row._id,
          label: row.name,
        }));
        dispatcher(setLocations(selectors));
      } catch (err) {
        console.error("Error getting locations:", err);
      }
    };

    fetchLocations();
  }, [location]);

  return (
    <>
      {showTabs && <MenuNav ubication={"locations"} />}
      <Paper>
        <Box p={4}>
          <Typography variant="h5">{t("organization:Locations")}</Typography>
          <br />
          <Formik
            initialValues={
              initialValues === null
                ? {
                    country: "",
                    address: "",
                    latitude: "",
                    longitude: "",
                    name: "",
                    zipCode: "",
                    timeZone: "",
                    language: "",
                    currency: "",
                  }
                : initialValues
            }
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form id="d" autoComplete="off">
                <LocationsForm formik={formik} />
              </Form>
            )}
          </Formik>
          <br />
          <hr />
          <br />
          <Typography variant="h5">{t("organization:Ubicaciones")}</Typography>
          <br />
          <BasicTable rows={rows} handleClick={handleClick}>
            <TableCell>{t("organization:LocationName")}</TableCell>
            <TableCell>{t("organization:address")}</TableCell>
            <TableCell>{t("organization:zipCode")}</TableCell>
          </BasicTable>
        </Box>
      </Paper>
    </>
  );
};
