import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { Toast, UIButton } from "components";
import Swal from "sweetalert2";
import {
  deleteStaffRequest,
  getJobPositions,
  postStaffRequest,
  putStaffRequest,
  selectingEmployeeList,
} from "api";
import { useDispatch } from "react-redux";
import {
  setStaffRequest,
  setVacancyReasons,
  recruitmentSelect,
  setUpdateStaffs,
} from "@redux/slices/recruitment";
import { authUserSelect } from "@redux/slices/authUser";
import { useNavigate } from "react-router-dom";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { useAccess } from "hooks";
import isEqual from "lodash/isEqual";
import { FormStaffRequest } from "./FromStaffRequest";
import { useEffect, useState } from "react";
import { getVacancyReasons } from "api";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import NoteAddIcon from "@mui/icons-material/NoteAdd";

import "./styles.css";

export const StaffRequest = ({
  btnBack = true,
  btnsActions = true,
  setOpen = null,
  request = false,
}) => {
  const { t } = useTranslation(["recruitment"]["general"]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { staffRequest } = useSelector(recruitmentSelect);
  const { user } = useSelector(authUserSelect);

  const [jobsProfile, setJobsProfile] = useState(null);
  const [tipeForm, setTipeForm] = useState(null);

  const { HasPermissions } = useAccess();
  const { canAdd, canChange, canDelete } = HasPermissions(
    "/cereza-organization/recruitment/staff-requests"
  );
  const { canAdd: canAddVacancyReasons } = HasPermissions(
    "/cereza-organization/recruitment/vacancy-reasons"
  );

  useEffect(() => {
    const listJobsProfile = async () => {
      try {
        let { data: employees } = await selectingEmployeeList();
        let { data: jobPositions } = await getJobPositions(null);

        jobPositions = jobPositions.reduce((acc, j) => {
          j.occupiedPositions = _.filter(
            employees,
            (o) => o.positionId === j._id
          ).length;
          j.available = j.occupiedPositions < j.quantity;

          acc[j._id] = j;

          return acc;
        }, {});

        setJobsProfile(
          _.map(
            _.filter(jobPositions, (job) => {
              return job.available && job.status === "APPROVED";
            }),
            (job) => ({
              ...job,
              value: job._id,
              label: job.title,
            })
          )
        );
      } catch (e) {
        console.log("error get jobs profile");
      }
    };

    listJobsProfile();

    const listVacancyReasons = async () => {
      try {
        const { data } = await getVacancyReasons(null);
        dispatch(setVacancyReasons(data));
      } catch (e) {
        console.log("error get jobs profile");
      }
    };

    listVacancyReasons();
  }, []);

  const onSubmit = async (values) => {
    let message = "";
    values.applicant = user?._id;

    const job = jobsProfile.find((obj) => obj._id === values?.jobPosition);

    let payload = { ...values };

    if (tipeForm === "AumentarVacantesPuesto") {
      payload = { ...payload, vacancies: values.vacancies + job?.quantity };
    }

    try {
      if (payload._id) {
        const { data } = await putStaffRequest(payload);
        dispatch(setStaffRequest(data));
        message = t("recruitment:staffrequestsuccessfullyupdate");
      } else {
        const { data } = await postStaffRequest(payload);
        dispatch(setStaffRequest(data));
        message = t("recruitment:staffrequestsuccessfullycreated");
      }
      dispatch(setUpdateStaffs(true));
      if (setOpen !== null) {
        setOpen(false);
      }
      Toast.fire({ icon: "success", title: message });
    } catch (e) {
      Toast.fire({
        icon: "error",
        title: t("recruitment:staffrequesterrorsubmit"),
      });
      console.log("Error to submit form: ", e);
    }
  };

  const deleteItem = async (item) => {
    try {
      await deleteStaffRequest(item);

      dispatch(setUpdateStaffs(true));
      if (setOpen !== null) {
        setOpen(false);
      }

      Toast.fire({
        icon: "success",
        title: t("recruitment:staffrequestsuccessfullydelete"),
      });

      dispatch(
        setStaffRequest({
          applicant: "",
          vacancyReason: [],
          vacancies: 1,
          jobPosition: "",
        })
      );
    } catch (err) {
      console.log(err);
      Toast.fire({
        icon: "error",
        title: t("recruitment:staffrequesterrordelete"),
      });
    }
  };

  return (
    <Box p={2}>
      {request && tipeForm === null && (
        <div className="Box-btn">
          <div
            className="btn-option"
            onClick={() => setTipeForm("StaffRequest")}
          >
            <b>{t("recruitment:StaffRequest")}</b>
            <GroupAddIcon />
          </div>

          <div
            className="btn-option"
            onClick={() => setTipeForm("AumentarVacantesPuesto")}
          >
            <b>{t("recruitment:AumentarVacantesPuesto")}</b>
            <NoteAddIcon />
          </div>
        </div>
      )}
      {(request === false || tipeForm !== null) && (
        <>
          <Typography variant="h5" mb={2}>
            {tipeForm === "StaffRequest"
              ? t("recruitment:StaffRequest")
              : t("recruitment:VacanciesRequest")}
          </Typography>
          {jobsProfile ? (
            <Formik
              initialValues={staffRequest}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {(formik) => (
                <Form id={"d"} autoComplete="off">
                  <FormStaffRequest
                    formik={formik}
                    canAdd={canAdd}
                    canChange={canChange}
                    canAddVacancyReasons={canAddVacancyReasons}
                    jobsProfile={jobsProfile}
                    btnsActions={btnsActions}
                    tipeForm={tipeForm}
                  />
                  {btnsActions && (
                    <Stack
                      mt={2}
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 1, sm: 1, md: 1 }}
                    >
                      {tipeForm !== null && (
                        <UIButton
                          variant="contained"
                          startIcon={<ChevronLeftIcon />}
                          onClick={() => setTipeForm(null)}
                          disabled={formik.isSubmitting}
                          sx={{}}
                        />
                      )}
                      {btnBack && (
                        <UIButton
                          variant="contained"
                          startIcon={<ChevronLeftIcon />}
                          onClick={() =>
                            navigate(
                              "/cereza-organization/recruitment/staff-requests"
                            )
                          }
                          disabled={formik.isSubmitting}
                          sx={{}}
                        />
                      )}

                      {((canAdd && !formik?.values?._id) ||
                        (canChange && formik?.values?._id)) && (
                        <UIButton
                          type="submit"
                          label={
                            formik?.values?._id
                              ? t("general:Actualizar")
                              : t("general:Guardar")
                          }
                          loading={formik.isSubmitting}
                          sx={{}}
                        />
                      )}
                      {canDelete && formik?.values?._id && (
                        <UIButton
                          label={t("general:Eliminar")}
                          onClick={() => {
                            Swal.fire({
                              title: t("general:Are"),
                              text: t("general:Youwon"),
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: t("general:deleteit"),
                            }).then((result) => {
                              if (result.isConfirmed) {
                                deleteItem(formik.values);
                              }
                            });
                          }}
                          disabled={formik.isSubmitting}
                          sx={{}}
                        />
                      )}
                      {!isEqual(formik?.values, formik?.initialValues) && (
                        <>
                          {canAdd && (
                            <UIButton
                              label={t("general:Nuevo")}
                              onClick={() =>
                                formik.setValues(formik.initialValues)
                              }
                              disabled={formik.isSubmitting}
                              sx={{}}
                            />
                          )}
                        </>
                      )}
                    </Stack>
                  )}
                </Form>
              )}
            </Formik>
          ) : (
            <Box
              p={2}
              sx={{
                width: "100%",
                height: "100%",
                display: "grid",
                placeItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
