import { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer,
  Toolbar,
  Typography,
  CssBaseline,
  Divider,
  IconButton,
  Stack,
  Badge,
  Avatar,
  Button,
  Card,
  CardContent,
  CardActions,
  Alert,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import ChatBubbleOutlinedIcon from "@mui/icons-material/ChatBubbleOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import BrandMarca from "../assets/brand/marca.png";
import { SideNav } from "./SideNav";
import { FooterCopyright } from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { setOpenDialogSearch } from "@redux/slices/general";
import { DialogSearch } from "modules/SearchAdvanced/DialogSearch";
import { useTranslation } from "react-i18next";
import { authUserSelect } from "@redux/slices/authUser";
import { setCurrentPlan, tenantSettingsSelect } from "@redux/slices/tenant";
import { calcularDiasPasados } from "helpers";
import { MainBox } from "components";
import { MenuAvatar } from "./MenuAvatar";
import { Notifications } from "./Notifications";
import { ShortCuts } from "./ShortCuts";
import { postChangeEmail, getPlan } from "api";
import { useNavigate } from "react-router-dom";
import { getFiles } from "api";
import {
  parameterizationSelect,
  setLogo,
} from "@redux/slices/parameterization";
import avatar from "assets/illustrations/avatar.jpeg";
import { useSocket } from "hooks";
import { setEmail, setTimer, publicSelect } from "@redux/slices/public";
import { ChangePassword } from "./ChangePassword";

const ColorBaseAppBar = "#606060";

const ButtonActionAppBar = styled(Button)(({ theme }) => ({
  color: ColorBaseAppBar,
  variant: "outlined",
  padding: "5px 5px",
  borderRadius: "15px",
  border: "2px solid",
  minWidth: "40px",
  borderColor: ColorBaseAppBar,
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const drawerWidth = 400;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const DrawerHeaderLeft = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

function isWithinFiveMinutes(dateTime) {
  const inputTime = new Date(dateTime);

  const currentTime = new Date();

  const differenceInMilliseconds = currentTime - inputTime;

  const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

  return differenceInMinutes <= 5;
}

export default function TemplateGridUI({ children }) {
  const { notifications, startConnection, isConnected, fetchNotifications } =
    useSocket();

  useEffect(() => {
    if (!isConnected) {
      startConnection();
    }
  }, [isConnected, startConnection]);

  const { t } = useTranslation(["sidenav"]);
  const navigate = useNavigate();

  const dispatcher = useDispatch();
  const { user } = useSelector(authUserSelect);
  const { tenant, currentPlan } = useSelector(tenantSettingsSelect);
  const { darkBackgroundColor, mode, logo } = useSelector(
    parameterizationSelect
  );
  const { timer } = useSelector(publicSelect);

  const [feedBackUserFetch, setFeedBackUserFetch] = useState({
    spinner: true,
    msg: null,
  });

  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNotification, setAnchorElNotification] = useState(null);
  const [anchorElShortCuts, setAnchorElShortCuts] = useState(null);

  const theme = useTheme();
  const openAvatar = Boolean(anchorEl);
  const openNotification = Boolean(anchorElNotification);
  const openShortCuts = Boolean(anchorElShortCuts);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickNotification = (event) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleCloseNotification = () => {
    setAnchorElNotification(null);
  };

  const handleCloseShortCuts = () => {
    setAnchorElShortCuts(null);
  };

  const handleClickShortCuts = (event) => {
    setAnchorElShortCuts(event.currentTarget);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [stateDrawer, setStateDrawer] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setStateDrawer({ ...stateDrawer, [anchor]: open });
  };

  useEffect(() => {
    const fetchPlan = async () => {
      try {
        const { data } = await getPlan(tenant.currentPlan);
        dispatcher(setCurrentPlan(data));
      } catch (error) {
        console.log(error);
      }
    };
    if (tenant?.currentPlan) {
      fetchPlan();
    }
  }, []);

  function evaluarNumero(numero) {
    if (numero > 15) {
      return "0";
    } else {
      const faltantes = 15 - numero;
      return faltantes;
    }
  }

  const handleUpdatePassword = async (values) => {
    try {
      setOpenChangePassword(true);

      if (timer === null || !isWithinFiveMinutes(timer)) {
        await postChangeEmail({ email: user?.email });
        await dispatcher(setTimer(new Date()));
      }

      await dispatcher(setEmail(user?.email));
      setFeedBackUserFetch({
        spinner: false,
        msg: null,
      });
    } catch (error) {
      setFeedBackUserFetch({
        spinner: false,
        msg: "Error al intentar esta acción, por favor intenta nuevamente",
      });
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchSource = async () => {
      try {
        const { data } = await getFiles(tenant?._id, tenant?.companyLogo);
        dispatcher(setLogo(data.content));
      } catch (err) {
        console.error("Error getting photo: ", err);
      }
    };
    if (logo === null && tenant?._id && tenant?.companyLogo) {
      fetchSource();
    }
  }, []);

  const heightDrawerHeader = currentPlan?.name === "Trial" ? "130px" : "65px";

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: mode === "dark" && darkBackgroundColor,
      }}
    >
      <CssBaseline />
      <AppBar
        id="cereza-app-bar"
        position="fixed"
        open={open}
        style={{
          backgroundColor: mode === "dark" ? darkBackgroundColor : "#fff",
        }}
      >
        {currentPlan?.name === "Trial" && (
          <Alert severity="warning">
            {`${t("Versióndemoenplanprofesional,quedan")} ${evaluarNumero(
              calcularDiasPasados(tenant?.createdAt)
            )} ${t("día(s)deprueba,contrataalgunodenuestrosplanes")}`}

            <u onClick={() => navigate("/cereza-organization/Planes")}>
              {t("PLANES")}
            </u>
          </Alert>
        )}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr auto",
            alignItems: "center",
            maxHeight: "64px",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                color: ColorBaseAppBar,
                mr: 2,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <img src={logo !== null ? logo : BrandMarca} height="50px" />
          </Toolbar>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
              justifyContent: "end",
              gap: "1rem",
            }}
          >
            <Stack direction="row" spacing={2}>
              <ButtonActionAppBar
                sx={{
                  pl: 2,
                  pr: 2,
                  display: {
                    md: "inline-flex",
                    xs: "none",
                  },
                }}
                onClick={() => dispatcher(setOpenDialogSearch(true))}
              >
                <SearchIcon />
              </ButtonActionAppBar>
              <ButtonActionAppBar
                sx={{
                  display: {
                    md: "inline-flex",
                    xs: "none",
                  },
                }}
                onClick={handleClickShortCuts}
                aria-controls={openShortCuts ? "account-menu" : undefined}
                aria-expanded={openShortCuts ? "true" : undefined}
                aria-haspopup="true"
              >
                <AddIcon />
              </ButtonActionAppBar>
              <ButtonActionAppBar
                sx={{
                  display: {
                    md: "inline-flex",
                    xs: "none",
                  },
                }}
                onClick={handleClickNotification}
                aria-controls={openNotification ? "account-menu" : undefined}
                aria-expanded={openNotification ? "true" : undefined}
                aria-haspopup="true"
              >
                <Badge
                  color="secondary"
                  badgeContent={notifications?.length ?? 0}
                >
                  <NotificationsNoneIcon />
                </Badge>
              </ButtonActionAppBar>
              <ButtonActionAppBar onClick={toggleDrawer("right", true)}>
                <QuestionMarkIcon />
              </ButtonActionAppBar>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
                onClick={handleClick}
                aria-controls={openAvatar ? "account-menu" : undefined}
                aria-expanded={openAvatar ? "true" : undefined}
                aria-haspopup="true"
              >
                <Avatar src={user?.thumbnail ?? avatar} />
              </StyledBadge>
            </Stack>
            <ShortCuts
              open={openShortCuts}
              anchorEl={anchorElShortCuts}
              handleCloseNotification={handleCloseShortCuts}
            />
            <Notifications
              open={openNotification}
              anchorEl={anchorElNotification}
              handleCloseNotification={handleCloseNotification}
              notifications={notifications}
              fetchNotifications={fetchNotifications}
            />
            <MenuAvatar
              openAvatar={openAvatar}
              anchorEl={anchorEl}
              handleClose={handleClose}
              user={user}
              handleUpdatePassword={handleUpdatePassword}
            />
          </Box>
        </Box>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <SideNav />
      </Drawer>
      <Drawer
        anchor="right"
        open={stateDrawer["right"]}
        onClose={toggleDrawer("right", false)}
        sx={{
          "&.MuiDrawer-root .MuiDrawer-paper": {
            maxWidth: 340,
            minWidth: 340,
          },
        }}
      >
        <DrawerHeaderLeft>
          <Typography variant="h6">Descripción de ayuda</Typography>
        </DrawerHeaderLeft>
        <Divider />

        <Stack
          spacing={2}
          sx={{
            margin: "15px",
          }}
        >
          <Card variant="outlined">
            <CardContent>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                dignissim leo ac interdum feugiat. Interdum et malesuada fames
                ac ante ipsum primis in faucibus
              </Typography>
            </CardContent>
            <CardActions></CardActions>
          </Card>

          <Button
            size="large"
            variant="outlined"
            startIcon={<ChatBubbleOutlinedIcon />}
            sx={{
              justifyContent: "flex-start",
            }}
          >
            Soporte por chat
          </Button>
          <Button
            size="large"
            variant="outlined"
            startIcon={<MailOutlineIcon />}
            sx={{
              justifyContent: "flex-start",
            }}
          >
            Crear ticket
          </Button>
        </Stack>
      </Drawer>
      <Main
        open={open}
        style={{
          minHeight: "100vh",
          display: "grid",
          gridTemplateRows: `${heightDrawerHeader} auto 50px`,
        }}
      >
        <div style={{ height: heightDrawerHeader }} />
        <MainBox open={open}>{children}</MainBox>
        <FooterCopyright />
      </Main>
      <DialogSearch />
      <ChangePassword
        open={openChangePassword}
        setOpen={setOpenChangePassword}
        feedBackUserFetch={feedBackUserFetch}
      />
    </Box>
  );
}
